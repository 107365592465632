<template>
  <div id="app">
    <headerSection />
    <transition
      name="page"
      mode="out-in"
    >
      <router-view />
    </transition>
    <footerSection />
  </div>
</template>
<script>
import headerSection from '@/components/layout/Header.vue';
import footerSection from '@/components/layout/Footer.vue';
export default {
  name: "App",
  data () {
    return {
      headerSectionElement: ''
    }
  },
  components: {
    headerSection,
    footerSection
  },
  watch: {
    '$route' () {
      this.handleStyles()
    }
  },
  created () {
    window.addEventListener('scroll', this.makeHeaderSticky)
  },
  mounted () {
    this.headerSectionElement = document.getElementById("headerContainer");
    this.handleStyles()
    window.addEventListener('scroll', this.makeHeaderSticky)
  },
  methods: {
    handleStyles () {
      if (this.$route.name === "home") {
        this.transparentHeader();
      } else {
        this.nonTransparentHeader();
      }
    },
    makeHeaderSticky () {
      var sticky = this.headerSectionElement.offsetTop;
      if (window.pageYOffset > sticky) {
        this.stickyHeader();
      } else {
        this.nonStickyHeader();
      }
    },
    stickyHeader () {
      this.headerSectionElement.classList.add("stickyHeader");
      if (this.$route.name === "home") {
        this.nonTransparentHeader();
        document.getElementById('homeLogo').classList.add('height0Class');
        document.getElementById('smallLogo').classList.remove('height0Class');
      } else {
         document.getElementById('logo').classList.add('height0Class');
        document.getElementById('smallLogo').classList.remove('height0Class');
      }

    },
    nonStickyHeader () {
      this.headerSectionElement.classList.remove("stickyHeader");
      if (this.$route.name === "home") {
        this.transparentHeader();
        document.getElementById('smallLogo').classList.add('height0Class');
        document.getElementById('homeLogo').classList.remove('height0Class');
      } else {
         document.getElementById('logo').classList.remove('height0Class');
        document.getElementById('smallLogo').classList.remove('height0Class');
      }
    },
    transparentHeader () {
      this.headerSectionElement.classList.add('transparentBackground');
    },
    nonTransparentHeader () {
      this.headerSectionElement.classList.remove('transparentBackground');
    }

  }
}
</script>

<style lang="scss">
#app {
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  width: inherit;
}
.page-enter-active,
.page-leave-active {
  transition: opacity 0.8s, transform 0.4s;
}
.page-enter,
.page-leave-to {
  opacity: 0.1;
  transform: translatey(-3%);
}
</style>
