<template>
  <div id="headerContainer">
    <div>
      <img
        alt="Ekem logo"
        src="@/assets/logo/logo.png"
        class="logo"
        id="logo"
        v-if="!homePage"
      >
      <img
        alt="Ekem logo"
        src="@/assets/logo/homeLogo.png"
        class="logo"
        id="homeLogo"
        v-if="homePage"
      >
      <img
        alt="Ekem logo"
        src="@/assets/logo/logo.png"
        class="logo height0Class"
        id="smallLogo"
      >
    </div>
    <navBar />
  </div>
</template>

<script>
import navBar from "@/components/NavBar.vue";
export default {
  name: 'headerSection',
  data () {
    return {
      homePage: false
    }
  },
  components: {
    navBar
  }, watch: {
    $route () {
      if (this.$route.name === "home") {
        this.homePage = true;
      } else {
        this.homePage = false;
      }
    }
  },
  mounted () {
    if (this.$route.name === "home") {
      this.homePage = true;
    } else {
      this.homePage = false;
    }

  }
}
</script>

<style lang="scss" scoped>
#headerContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 $commonLeftRightPadding;
  align-items: center;
  background-color: $yellow1;
  width: 100%;
  height: 90px;
  z-index: 999;
  transition: all 0.6s;
  @include media-md {
    height: 90px;
  }
  #logo {
    opacity: 1;
  }
  #smallLogo {
    opacity: 0;
  }
}

.logo {
  width: 70px;
  transition: opacity 1 s linear;
  @include media-md {
    width: 100px;
  }
}
#homeLogo{
  width: 100px;
}
</style>

