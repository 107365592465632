<template>
  <div id="homeSection3MainContainer">
    <div class="container">
      <div class="row">
        <div class="card">
          <img src="@/assets/icon/1.png" />
          <p>Cognitive development<br />
            through exploration and play</p>
        </div>
        <div class="card">
          <img src="@/assets/icon/2.png" />
          <p>Cultural awareness and<br />
            diversity
          </p>
        </div>
        <div class="card">
          <img src="@/assets/icon/3.png" />
          <p>Relationship & Character
            development
          </p>
        </div>
      </div>
      <div class="row">
        <div class="card">
          <img src="@/assets/icon/4.png" />
          <p>Physical development and
            awareness
          </p>
        </div>
        <div class="card">
          <img src="@/assets/icon/5.png" />
          <p>Numeracy
          </p>
        </div>
        <div class="card">
          <img src="@/assets/icon/6.png" />
          <p>Literacy, communication and
            language.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section3'
}
</script>

<style lang="scss" scoped>
#homeSection3MainContainer {
  width: 100%;
  height: auto;
  background-color: $offWhite;
  //margin-bottom:50px;
  .container {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 4rem $commonLeftRightPadding;
    align-items: center;
    align-content: center;
    justify-content: center;
    .row {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0 $commonLeftRightPadding;
      justify-content: center;
      @include media-md {
        width: 100%;
        max-width: 1024px;
        flex-direction: row;
        justify-content: space-around;
      }
    }
    .card {
      width: 250px;
      margin: 2rem;

      text-align: center;
      img {
        width: 25%;
      }
    }
  }
}
</style>

