<template>
  <div id="homeSection1MainContainer">
    <div id="homeSection1MainContainerOverlay">
    </div>
    <div class="textContainer">
      <h1>
        Learning happens<br />
        by <span class="yellowColor">Birth</span>.
      </h1>
      <p>
        Ekam helps to create a lifelong love<br />
        for learning in every child.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'section1'
}
</script>

<style lang="scss" scoped>
#homeSection1MainContainer {
  width: 100%;
  background-image: url("../../../assets/image/wallMob.jpg");
  @include media-md{
    background-image: url("../../../assets/image/wall.jpg");
  }
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: $white;
  height: auto;
  min-height: 100vh;
  @include media-md {
    height: 100vh;
  }
  .textContainer {
    position: absolute;
    top: 150px;
    left: $commonLeftRightPadding;
    z-index: 3;
    h1 {
      font-weight: 800;
      @include media-lg {
        font-size: 3.2rem;
      }
      @include media-xl {
        font-size: 4rem;
      }
      @include media-xxl {
        font-size: 4.8rem;
      }
    }
    @include media-md {
      top: 30%;
      left: unset;
      right: $commonLeftRightPadding;
    }
  }
}
.yellowColor {
  color: $yellow1;
}
#homeSection1MainContainerOverlay {
  width: 100%;
  height: 100vh;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
}
</style>

