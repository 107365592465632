<template>
  <div class="contact">
    <div class="container">
      <h2 id="enquiryFormHead">Enquiry Form</h2>
      <form
        id="enquiryForm"
        @submit.prevent="formSubmit"
      >
        <div class="formElement">
          <label for="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            v-model="formData.name"
          >
        </div>
        <div class="formElement">
          <label for="phone">Phone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            v-model="formData.phone"
          >
        </div>
        <div class="formElement">
          <label for="email">Email</label>
          <input
            type="text"
            name="email"
            id="email"
            v-model="formData.email"
          >
        </div>
        <div class="formElement">
          <label for="Course">Course</label>
          <input
            type="text"
            name="Course"
            id="Course"
            v-model="formData.course"
          >
        </div>
        <div class="formElement">
          <label for="student_name">Student Name</label>
          <input
            type="text"
            name="student_name"
            id="student_name"
            v-model="formData.studentName"
          >
        </div>
        <div class="formElement">
          <label for="age">Student Age</label>
          <input
            type="text"
            name="age"
            id="age"
            v-model="formData.age"
          >
        </div>
        <div class="formElement">
          <label for="gender">Student Gender</label>
          <input
            type="text"
            name="gender"
            id="gender"
            v-model="formData.gender"
          >
        </div>
        <div class="formElement">
          <label for="details">Details</label>
          <textarea
            name="details"
            id="details"
            v-model="formData.details"
          ></textarea>
        </div>
        <label
          v-show="successMessage"
          id="successMessage"
        >We will contact you soon</label>
        <input
          type="submit"
          value="Submit Enquiry"
          id="submitBt"
          v-show="this.submitShow"
        >
      </form>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data () {
    return {
      course: '',
      submitShow: true,
      successMessage: false,
      formData: {}
    }

  },
  methods: {
    formSubmit () {
      this.submitShow = false;
      /*const options = {
        method: 'POST',
        headers: { 'content-type': 'application/form-data' },
        data: this.formData,
        url: 'form.php',
      };*/
      axios.post('form.php',this.formData)
        .then(function (response) {
          console.log(response.data);
          if (response.data != '0') {
            this.successMessage = true;
          } else {
            this.submitShow = true;
          }
        }.bind(this))
        .catch(function (error) {
          console.log(error);
          this.submitShow = true;
        }.bind(this));
    }
  },
  created () {
    this.formData.course = new URLSearchParams(window.location.search).get('course');
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding: 0 $commonLeftRightPadding;
  width: 100%;
  max-width: 600px;
  margin: 4rem auto;
  #enquiryFormHead {
    color: $yellow2;
    font-weight: 400;
  }
  #enquiryForm {
    margin: 2rem 0;
  }
  #submitBt {
    border: none;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    color: $white;
    background-color: $yellow3;
  }
  #successMessage {
    color: $yellow3;
    padding: 0.5rem 1rem;
  }
  .formElement {
    display: flex;
    margin: 1rem 0;
    flex-direction: column;
    @include media-md {
      flex-direction: row;
    }
    label,
    input,
    textarea {
      padding: 0;
      margin: 0 3px;
      border: none;
    }
    input,
    textarea {
      flex: 1;
      border-bottom: 1px solid $yellow2;
    }
    label {
      min-width: 140px;
      font-size: 0.9rem;
      margin-bottom: 4px;
      @include media-md {
        margin-bottom: 0;
      }
    }
    textarea {
      min-height: 100px;
    }
  }
  .onlineAdmissionFormLink {
    color: $yellow1;
    border: 1px solid $yellow1;
    text-align: center;
    border-radius: 1rem;
    padding: 0.5rem 0;
  }
}
</style>
