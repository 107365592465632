import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Contact from './views/Contact.vue';
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/courses',
      name: 'courses',
      component: () => import(/* webpackChunkName: "common" */ './views/Courses.vue')
    },
    {
      path: '/courseDetails/:course',
      name: 'courseDetails',
      component: () => import(/* webpackChunkName: "common" */ './views/CourseDetails.vue')
    },
    {
      path: '/curriculum',
      name: 'curriculum',
      component: () => import(/* webpackChunkName: "common" */ './views/Curriculum.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import(/* webpackChunkName: "common" */ './views/About.vue')
    },
    {
      path: '/gallery',
      name: 'gallery',
      component: () => import(/* webpackChunkName: "common" */ './views/Gallery.vue')
    },
    {
      path: '/testimonials',
      name: 'testimonials',
      component: () => import(/* webpackChunkName: "common" */ './views/Testimonials.vue')
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/admissionForm',
      name: 'admissionForm',
      component: () => import(/* webpackChunkName: "common" */ './views/AdmissionForm.vue')
    },
    {
      path: '/events',
      name: 'events',
      component: () => import(/* webpackChunkName: "common" */ './views/Events.vue')
    },
    {
      path: '/events/MonsoonMagic',
      name: 'eventgallery',
      component: () => import(/* webpackChunkName: "common" */ './views/Events/MonsoonMagic.vue')
    }
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active"
}
)
/*


    {
      path: '/events',
      name: 'events',
      component: () => import('./views/Events.vue')
    },
    {
      path: '/events/MonsoonMagic',
      name: 'eventgallery',
      component: () => import( './views/Events/MonsoonMagic.vue')
    }
*/

