import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/scss/main.scss'
import VueExpandableImage from 'vue-expandable-image'
Vue.use(VueExpandableImage)
Vue.config.productionTip = true

new Vue({
  router,
  store,
  render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
