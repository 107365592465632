<template>
  <div class="home">
    <section1 />
    <section2 />
    <section3 />
  </div>
</template>

<script>
import section1 from "@/components/pages/home/Section1";
import section2 from "@/components/pages/home/Section2";
import section3 from "@/components/pages/home/Section3";
export default {
  name: 'home',
  components: {
    section1,
    section2,
    section3
  },
  beforeCreate () {

  }
}
</script>

<style lang="scss" scoped>
</style>


