<template>
  <div>
    <div id="nav">
      <router-link
        to="/"
        class="routerLink"
      >Home</router-link>
      <router-link
        to="/about"
        class="routerLink"
      >About</router-link>
      <router-link
        to="/courses"
        class="routerLink"
      >Courses</router-link>
      <router-link
        to="/curriculum"
        class="routerLink"
      >Curriculum</router-link>
      <router-link
        to="/gallery"
        class="routerLink"
      >Gallery</router-link>
     
      <router-link
        to="/testimonials"
        class="routerLink"
      >Testimonials</router-link>
     <router-link
        to="/contact"
        class="routerLink"
      >Contact</router-link>

    </div>
    <div
      id="mobileMenuIcon"
      @click="menuToggle"
    >
      <div
        class="mobileMenuIconBar"
        id="mobileMenuIconBar1"
      >
        <div
          class="mobileMenuIconBarColor"
          v-bind:class="{ mobileMenuIconBarColorFullWidth: mobileMenuState }"
        ></div>
      </div>
      <div
        class="mobileMenuIconBar"
        id="mobileMenuIconBar2"
      ></div>
      <div
        class="mobileMenuIconBar"
        id="mobileMenuIconBar3"
      >
        <div
          class="mobileMenuIconBarColor"
          v-bind:class="{ mobileMenuIconBarColorFullWidth: mobileMenuState }"
        ></div>
      </div>
    </div>
    <div
      id="mobnav"
      v-bind:class="{ mobileMenuShow: mobileMenuState }"
    >
      <router-link
        to="/"
        class="routerLink"
      >Home</router-link>
      <router-link
        to="/about"
        class="routerLink"
      >About</router-link>
      <router-link
        to="/courses"
        class="routerLink"
      >Courses</router-link>
      <router-link
        to="/curriculum"
        class="routerLink"
      >Curriculum</router-link>
      <router-link
        to="/gallery"
        class="routerLink"
      >Gallery</router-link>
      
      <router-link
        to="/testimonials"
        class="routerLink"
      >Testimonials</router-link>
      <router-link
        to="/contact"
        class="routerLink"
      >Contact</router-link>
    </div>
  </div>

</template>

<script>

export default {
  name: 'navBar',
  data () {
    return {
      'mobileMenuState': false
    }
  },
  methods: {
    menuToggle () {
      if (this.mobileMenuState) {
        this.mobileMenuState = false;
      } else {
        this.mobileMenuState = true;
      }

    }
  }, watch: {
    $route () {
      this.mobileMenuState = false;
    }
  }
}
</script>

<style lang="scss" scoped>
#nav {
  display: none;
  font-size:0.9rem;
  @include media-lg {
    display: block;
  }
  .routerLink {
    margin: 0;
    margin-left: 1rem;
  }
}
#mobnav {
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-top: 90px;
  background-color: $yellow1;
  display: flex;
  flex-direction: column;
  text-align: right;
  padding-right: $commonLeftRightPadding;
  opacity: 0.8;
  transition: opacity 0.3s, visibility 0.3s, transform 0.9s;
  transform: translateX(100%);
  @include media-lg {
    display: none !important;
  }
  .routerLink {
    margin: 10px 0;
  }
}
#mobileMenuIcon {
  cursor: pointer;
  width: 50px;
  display: block;
  padding:10px;
  @include media-lg {
    display: none !important;
  }
  z-index: 999;
  .mobileMenuIconBar {
    height: 4px;
    padding: 0;
    margin: 2px auto;
    background-color: white;
    border-radius: 20%;
    overflow: hidden;
    float:right;
  }
  .mobileMenuIconBarColor {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $yellow1;
    height: 100%;
    transition: transform 0.8s;
    border-radius: 20%;
    transform: translateX(100%);
  }

  #mobileMenuIconBar1 {
    width: 100%;
  }
  #mobileMenuIconBar2 {
    width: 80%;
  }
  #mobileMenuIconBar3 {
    width: 60%;
  }
}
.mobileMenuIconBarColorFullWidth {
  //width: 100% !important;
  transform: translateX(0) !important;
}
.mobileMenuShow {
  transform: translateX(0) !important;
  visibility: visible !important;
  opacity: 1 !important;
}
</style>

