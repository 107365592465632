<template>
  <div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3806.7791189007203!2d78.40271261487682!3d17.422384688057555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9767e81435f3%3A0xa249c22a77dad83f!2sEKAM+Playschool%2C+Remedial+and+Daycare.!5e0!3m2!1sen!2sin!4v1559238131843!5m2!1sen!2sin"
      width="600"
      height="450"
      frameborder="0"
      style="border:0;width:100%;height:200px;"
      allowfullscreen
    ></iframe>
    <div>
      <div id="footerSectionContainer">
        <img
          src="@/assets/logo/bottomLogo.png"
          class="logo"
        />
        <div>
          <p>EKAM Playschool, Remedial and Daycare.</p>
          <p>Plot No. 39, Road Number 7,</p>
          <p>Prashasan Nagar, Jubilee Hills, 500101.</p>
        </div>
        <div id="footerEmailPhoneContainer">
          <div id="footerPhoneNumbersContainer"> +91 990 2929 520<br />
            +91 955 0569 816<br />
            +91 948 2293 485
          </div>
          <div id="footerEmailContainer">
            <h5>admissions@ekamschools.com</h5>
            <small>© 2018 Quest Education Licensing Ltd.</small>
            <small>All rights reserved. Privacy Policy</small>
            <small>Inked by <a href="http://ninetysix.in" target="_blank">ninetysix</a></small>
          </div>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'footerSection'
}
</script>

<style lang="scss" scoped>
#footerSectionContainer {
  background-color: $brown;
  width: 100%;
  height: auto;
  padding: 50px $commonLeftRightPadding 50px $commonLeftRightPadding;
  @include media-md {
    padding: 50px $commonLeftRightPadding;
  }
  display: flex;
  flex-direction: column;
  color: $white;
  .logo {
    width: 200px;
  }
  #footerEmailPhoneContainer {
    display: flex;
    flex-direction: column;
    padding-top: $commonLeftRightPadding;
    @include media-md {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    #footerPhoneNumbersContainer {
      min-width: 190px;
      margin: 1rem 0;
    }
    #footerEmailContainer {
      @include media-md {
        text-align: right;
      }
      h5 {
        margin-top: 0;
        margin: 1rem 0;
      }
      small {
        display:block;
        text-align: left;
        line-height: 0.9;
        font-size: 0.5rem;
        margin: 0.5rem 0;
        @include media-md {
          font-size: 0.8rem;
          text-align: right;
        }
      }
    }
  }
}
</style>

