<template>
  <div id="homeSection2MainContainer">
    <div class="container">
      <div class="first">
        <h1 class="hd hd1">“I DO,</h1>
        <h1 class="hd hd2">&nbsp;&nbsp;&nbsp;WE DO,</h1>
        <h1 class="hd hd3">&nbsp;&nbsp;&nbsp;YOU DO”</h1>
      </div>
      <div class="second">
        <p>
          Finnish ECEC (Early Childhood Education Care) is
          based on an integrated approach to care,
          education and teaching, the so called educare
          model. Critically teachers must support the
          development of the children’s play with a
          systematic and goal oriented approach by either
          guiding it from outside or participating in it.
        </p>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'section2'
}
</script>

<style lang="scss" scoped>
#homeSection2MainContainer {
  width: 100%;
  height: auto;
  @include media-md {
    height: 100vh;
  }
  background-color: $white;
  .container {
    display: flex;
    min-height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 4rem $commonLeftRightPadding;
    align-items: center;
    align-content: center;
    justify-content: center;
    @include media-md {
      flex-direction: row;
      .first,
      .second {
        flex: 1;
        max-width: 400px;
        padding: 0 $commonLeftRightPadding;
      }
    }
    .first {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin: 0 0 4rem 0;
      
      .hd {
        margin-top: 0.1rem;
        font-size:3.2rem;
        padding:0;
      }
      .hd1 {
        color: $yellow1;
      }
      .hd2 {
        color: $yellow2;
      }
      .hd3 {
        color: $yellow3;
      }
    }
    .second {
      p {
        border-top: 1px solid $red;
        padding: 0;
        padding:4rem 0 0 0;
        @include media-md {
          margin-top: 0;
          padding-left: 2rem;
          padding-top:0;
          border-top:none;
          border-left: 1px solid $red;
        }
      }
    }
  }
}
.yellowColor {
  color: $yellow1;
}
</style>

